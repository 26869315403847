import { Button, Card, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { DashOutlined, EyeOutlined, PicCenterOutlined, PhoneOutlined, ReloadOutlined } from "@ant-design/icons";

export default function Stats({ data, className }) {
  const navigate = useNavigate();
  return (
    <Card
      bordered={false}
      title="Статистика компании"
      extra={
        <Button onClick={() => navigate(0)} type="link" className="p-0 h-fit m-0" icon={<ReloadOutlined />}>
          Обновить
        </Button>
      }
      className={className}
    >
      <div className="grid grid-cols-3 gap-2 m-0">
        <div className="col-span-3 lg:col-span-1">
          <Tooltip placement="bottom" title="Просмотры">
            <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
              <span className="block text-center text-sm font-medium mb-1 w-full">
                {data?.company?.clicks_metric <= 0 ? <DashOutlined /> : data?.company?.clicks_metric}
              </span>
              <EyeOutlined className="mx-auto block text-neutral-400" style={{ fontSize: 17 }} />
            </div>
          </Tooltip>
        </div>
        <div className="col-span-3 lg:col-span-1">
          <Tooltip placement="bottom" title="Показы в общей ленте">
            <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
              <span className="block text-center text-sm font-medium mb-1 w-full">
                {data?.company?.shows_metric <= 0 ? <DashOutlined /> : data?.company?.shows_metric}
              </span>
              <PicCenterOutlined className="mx-auto block text-neutral-400" style={{ fontSize: 17 }} />
            </div>
          </Tooltip>
        </div>
        <div className="col-span-3 lg:col-span-1">
          <Tooltip placement="bottom" title="Запросы номера">
            <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
              <span className="block text-center text-sm font-medium mb-1 w-full">
                {data?.company?.contacts_metric <= 0 ? <DashOutlined /> : data?.company?.contacts_metric}
              </span>
              <PhoneOutlined className="mx-auto block text-neutral-400" style={{ fontSize: 17 }} />
            </div>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
}
