import { Card, Form, Cascader, Input, Select, Divider, Button, message, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { filter, propEq } from "ramda";
import Fade from "react-reveal/Fade";
import { GlobalOutlined, YoutubeFilled, RightOutlined, InstagramFilled, FacebookFilled } from "@ant-design/icons";
import isURL from "is-url";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Products } from "@utils";
import Logo from "./logo";
import Photos from "./photos";
import Files from "./files";
import Address from "./address";
import ProductOptions from "./options";
import Contacts from "./contacts";
import { useLocale } from "@contexts/locale";
import * as API from "@api";

export default function ProductInfo({ setStage, form, visible = false, allowBack = true, isApp = false }) {
  const [country, setCountry] = useState(null);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const { t } = useTranslation();
  const { locale } = useLocale();

  useEffect(() => {
    Promise.all([
      API.Categories.getAll({ limit: 500 }).then(res => res.data.rows),
      API.Countries.getAll({ limit: 200 }).then(res => res.data.rows),
      API.Cities.getAll({
        limit: 200,
        filter: { country_id: form.getFieldValue("country_id") || 1 }
      }).then(res => res.data.rows)
    ]).then(([categoriesData, countriesData, citiesData]) => {
      if (categoriesData.error || countriesData.error || citiesData.error) {
        return message.error(t("generic.serverError"), 2);
      }

      setCategories(
        categoriesData.map(cat => ({
          label: locale === "kk" ? cat?.name_kk : locale === "en" ? cat.name_en : cat.name,
          value: cat.id,
          children: filter(propEq("parent_id", cat.id), categoriesData).map(childCat => ({
            label: locale === "kk" ? childCat?.name_kk : locale === "en" ? childCat.name_en : childCat.name,
            value: childCat.id
          }))
        }))
      );

      setCountries(
        countriesData.map(country => ({
          label: locale === "kk" ? country?.name_kk : country.name,
          value: country.id
        }))
      );
      setCities(
        citiesData.map(city => ({
          label: locale === "kk" ? city?.name_kk : city.name,
          value: city.id,
          country_id: city.country_id
        }))
      );
    });
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    API.Cities.getAll({
      limit: 100,
      filter: { country_id: country || form.getFieldValue("country_id") }
    })
      .then(res => res.data.rows)
      .then(cities => {
        form.resetFields(["city_id"]);

        setCities(
          cities.map(city => ({
            label: locale === "kk" ? city?.name_kk : city.name,
            value: city.id,
            country_id: city.country_id
          }))
        );
      });
    /* eslint-disable-next-line */
  }, [country]);

  const onBackClick = () => {
    setStage(0);
  };

  const [isPriceRange, setIsPriceRange] = useState(form.getFieldValue("is_price_range"));

  const onNextClick = () => {
    form
      .validateFields([
        "description",
        "category_id",
        "country_id",
        "city_id",
        "title",
        "is_price_range",
        "price",
        "price_max",
        "website",
        ["meta", "youtube_url"]
      ])
      .then(() => {
        console.log('form.getFieldValue("contacts")', form.getFieldValue("contacts"));

        if (form.getFieldValue("contacts")?.length === 0) {
          throw Error(t("listings.phonePrompt"));
        }

        if (form.getFieldValue("image_list")?.length === 0 || !form.getFieldValue("image_list")) {
          throw Error(t("listings.photoPrompt"));
        }

        if (form.getFieldValue("type") === "franchise" && !form.getFieldValue([form.getFieldValue("type"), "logo"])) {
          throw Error(t("listings.logoPrompt"));
        }
      })
      .then(() => setStage(2))
      .catch(error => {
        try {
          if (error?.errorFields?.length > 0) {
            message.error(error?.errorFields[0]?.errors[0], 2);
          }
        } catch (error) {
          console.error(error);
        }

        return message.error(error?.message || t("generic.fillRequired"), 2);
      });
  };

  return (
    <Fade spy={visible} duration={300} distance="5px" bottom>
      <Contacts form={form} visible={visible} isApp={isApp} />
      <Card
        hidden={!visible}
        className={classNames("shadow-lg shadow-gray-100 transition-all", {
          "app-card": isApp
        })}
      >
        <h2 className="w-full text-center my-1">{t("listings.yourBusinessInfo")}</h2>
        <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-10">{t("listings.yourBusinessInfo")}</span>

        <Form.Item
          tooltip={t("listings.categoryTooltip")}
          label={t("listings.categoryTitle")}
          help={t("listings.categoryHelp")}
          name="category_id"
          rules={[
            {
              type: "array",
              required: true,
              message: t("listings.categoryPrompt")
            }
          ]}
        >
          <Cascader options={categories.sort((a, b) => a?.label?.localeCompare(b?.label))} placeholder={t("listings.categoryPlaceholder")} showSearch />
        </Form.Item>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 lg:col-span-1">
            <Form.Item
              label={t("listings.countryTitle")}
              tooltip={t("listings.countryTooltip")}
              help={t("listings.countryHelp")}
              name="country_id"
              rules={[
                {
                  required: true,
                  message: t("listings.countryPrompt")
                }
              ]}
              initialValue={1}
            >
              <Select
                placeholder={t("listings.countryPlaceholder")}
                onChange={setCountry}
                options={countries}
                showSearch
                optionFilterProp="label"
                filterSort={(optionA, optionB) => optionA.label?.toLowerCase()?.localeCompare(optionB?.label?.toLowerCase())}
              />
            </Form.Item>
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Form.Item
              label={t("listings.cityTitle")}
              tooltip={t("listings.cityTooltip")}
              help={t("listings.cityHelp")}
              name="city_id"
              rules={[
                {
                  required: true,
                  message: t("listings.cityPrompt")
                }
              ]}
            >
              <Select
                placeholder={t("listings.cityPlaceholder")}
                disabled={cities.length === 0}
                options={cities}
                showSearch
                optionFilterProp="label"
                filterSort={(optionA, optionB) => optionA?.label?.toLowerCase()?.localeCompare(optionB?.label?.toLowerCase())}
              />
            </Form.Item>
          </div>
        </div>

        <Address form={form} cities={cities} />

        <Form.Item
          label={t("listings.titleTitle")}
          tooltip={t("listings.titleTooltip")}
          name="title"
          rules={[
            {
              required: true,
              min: 8,
              max: 45,
              message: t("listings.titlePrompt"),
              whitespace: true
            },
            {
              validator: Products.validateTitle
            }
          ]}
          normalize={value => {
            return value.charAt(0).toUpperCase() + value.slice(1);
          }}
        >
          <Input placeholder={t("listings.titlePlaceholder")} />
        </Form.Item>

        <ProductOptions form={form} />

        <Form.Item
          label={t("listings.descriptionTitle")}
          tooltip={t("listings.descriptionTooltip")}
          name="description"
          normalize={value => {
            return value.charAt(0).toUpperCase() + value.slice(1);
          }}
          rules={[
            {
              required: true,
              min: 250,
              message: t("listings.descriptionPrompt")
            }
          ]}
        >
          <Input.TextArea rows={5} placeholder={t("listings.descriptionPlaceholder")} />
        </Form.Item>

        <Divider />

        <span className="text-base font-medium mb-1">{form.getFieldValue("type") === "franchise" ? t("generic.investment") : t("listings.sum")}</span>
        <p className="text-sm text-neutral-400 mb-5">
          {form.getFieldValue("type") === "investment"
            ? t("listings.sumToReceive")
            : form.getFieldValue("type") === "franchise"
            ? t("listings.sumToFranchise")
            : t("listings.sumToSell")}
        </p>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3 lg:col-span-1">
            <Form.Item
              label={t("listings.isFixedSum")}
              name="is_price_range"
              rules={[
                {
                  required: true,
                  message: t("generic.selectOption")
                }
              ]}
            >
              <Select onChange={setIsPriceRange}>
                <Select.Option value={false}>{t("generic.yes")}</Select.Option>
                <Select.Option value={true}>{t("generic.no")}, диапазон</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-3 lg:col-span-1">
            <Form.Item
              label={isPriceRange ? t("listings.sumMin") : t("listings.sumShort")}
              name="price"
              rules={[
                {
                  required: true,
                  message: t("listings.sumPrompt")
                }
              ]}
            >
              <InputNumber
                className="w-full"
                formatter={value => `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                controls={false}
                placeholder={t("listings.sumPlaceholder")}
              />
            </Form.Item>
          </div>
          {isPriceRange && (
            <div className="col-span-3 lg:col-span-1">
              <Form.Item
                label={t("listings.sumMaxTitle")}
                name="price_max"
                rules={[
                  {
                    required: isPriceRange,
                    message: t("listings.sumMaxPrompt")
                  }
                ]}
              >
                <InputNumber
                  className="w-full"
                  formatter={value => `₸ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  controls={false}
                  placeholder={t("listings.sumPlaceholder")}
                />
              </Form.Item>
            </div>
          )}
        </div>
        <Logo form={form} />
        <Photos form={form} />
        <Files form={form} />
        <Divider />
        <Form.Item
          label={t("listings.websiteTitle")}
          tooltip={t("listings.websiteTooltip")}
          name="website"
          hidden={form.getFieldValue("type") === "franchise"}
          rules={[
            {
              validator: (_, value) => {
                if (isURL(value) || !value) {
                  return Promise.resolve();
                }

                return Promise.reject();
              },
              message: t("listings.websitePrompt")
            }
          ]}
          normalize={value => {
            if (value === "" || !value) {
              return null;
            }

            return value;
          }}
        >
          <Input addonBefore={<GlobalOutlined />} allowClear placeholder={t("listings.websitePlaceholder")} type="url" />
        </Form.Item>
        <Form.Item
          label={t("listings.youtubeTitle")}
          tooltip={t("listings.youtubeTooltip")}
          name={["meta", "youtube_url"]}
          rules={[
            {
              validator: (_, value) => {
                if (isURL(value) || !value) {
                  return Promise.resolve();
                }

                return Promise.reject();
              },
              message: t("listings.youtubePrompt")
            }
          ]}
          normalize={value => {
            if (value === "" || !value) {
              return null;
            }

            return value;
          }}
        >
          <Input addonBefore={<YoutubeFilled />} allowClear placeholder={t("listings.youtubePlaceholder")} type="url" />
        </Form.Item>
        <Divider>Ссылки на соц.сети</Divider>
        <Form.Item
          label={t("listings.instagramTitle")}
          tooltip={t("listings.instagramTooltip")}
          name={["meta", "instagram_url"]}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }

                if (isURL(value)) {
                  // if starts with https://www.instagram.com/ or https://instagram.com/
                  if (value.startsWith("https://www.instagram.com/") || value.startsWith("https://instagram.com/")) {
                    return Promise.resolve();
                  }
                }

                return Promise.reject();
              },
              message: t("listings.instagramPrompt")
            }
          ]}
          normalize={value => {
            if (value === "" || !value) {
              return null;
            }

            return value;
          }}
        >
          <Input addonBefore={<InstagramFilled />} allowClear placeholder={t("listings.instagramPlaceholder")} type="url" />
        </Form.Item>
        <Form.Item
          label={t("listings.tiktokTitle")}
          tooltip={t("listings.tiktokTooltip")}
          name={["meta", "tiktok_url"]}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }

                if (isURL(value)) {
                  // if starts with https://www.tiktok.com/ or https://tiktok.com/
                  if (value.startsWith("https://www.tiktok.com/") || value.startsWith("https://tiktok.com/")) {
                    return Promise.resolve();
                  }
                }

                return Promise.reject();
              },
              message: t("listings.tiktokPrompt")
            }
          ]}
          normalize={value => {
            if (value === "" || !value) {
              return null;
            }

            return value;
          }}
        >
          <Input
            addonBefore={<img src="/img/icon-tiktok.svg" width={14} height={14} alt="TikTok" />}
            allowClear
            placeholder={t("listings.tiktokPlaceholder")}
            type="url"
          />
        </Form.Item>
        <Form.Item
          label={t("listings.facebookTitle")}
          tooltip={t("listings.facebookTooltip")}
          name={["meta", "facebook_url"]}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }

                if (isURL(value)) {
                  // if starts with https://www.facebook.com/ or https://facebook.com/
                  if (value.startsWith("https://www.facebook.com/") || value.startsWith("https://facebook.com/")) {
                    return Promise.resolve();
                  }
                }

                return Promise.reject();
              },
              message: t("listings.facebookPrompt")
            }
          ]}
          normalize={value => {
            if (value === "" || !value) {
              return null;
            }

            return value;
          }}
        >
          <Input addonBefore={<FacebookFilled />} allowClear placeholder={t("listings.facebookPlaceholder")} type="url" />
        </Form.Item>
        <Form.Item
          label={t("listings.youtubeChannelTitle")}
          tooltip={t("listings.youtubeChannelTooltip")}
          name={["meta", "youtube_channel_url"]}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }

                if (isURL(value)) {
                  // if starts with https://www.youtube.com/ or https://youtube.com/
                  if (value.startsWith("https://www.youtube.com/") || value.startsWith("https://youtube.com/")) {
                    return Promise.resolve();
                  }
                }

                return Promise.reject();
              },
              message: t("listings.youtubeChannelPrompt")
            }
          ]}
          normalize={value => {
            if (value === "" || !value) {
              return null;
            }

            return value;
          }}
        >
          <Input addonBefore={<YoutubeFilled />} allowClear placeholder={t("listings.youtubeChannelPlaceholder")} type="url" />
        </Form.Item>
        <Form.Item initialValue={locale} name={["meta", "locale"]} hidden>
          <Input defaultValue={locale} hidden disabled />
        </Form.Item>
        <Divider />
        <div className="flex justify-between items-center">
          <Button disabled={!allowBack} type="link" className="p-0" onClick={onBackClick}>
            {t("generic.back")}
          </Button>
          <Button onClick={onNextClick} type="primary">
            {t("generic.next")} <RightOutlined />
          </Button>
        </div>
      </Card>
    </Fade>
  );
}
