import { message } from "antd";
import { useEffect, useState } from "react";
import { pathOr, includes } from "ramda";
import ReasonFilter from "./filters/reason";
import ProductFilter from "./filters/product";
import DateRange from "./filters/date";
import Chart from "./chart";
import Table from "./table";
import * as API from "@api/moderator";

export default function Feedback({ showHeader = true, showFilters = ["outcome_reason", "created_at", "product_id"], defaultFilterValues = null }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filters] = useState({
    outcome_reason: [
      { id: "sold_sdelka", name: "Продали на Sdelka.kz" },
      { id: "sold_other_website", name: "Продали на другом сайте" },
      { id: "sold_friends", name: "Продали через знакомых" },
      { id: "sold_other", name: "Продали (своя причина)" },
      { id: "removed_low_calls", name: "Сняли (мало звонков)" },
      { id: "removed_changed_mind", name: "Сняли (передумали продавать)" },
      { id: "removed_other", name: "Сняли по другой причине" }
    ]
  });
  const [filter, setFilter] = useState({
    outcome_reason: pathOr(undefined, ["outcome_reason"], defaultFilterValues),
    product_id: pathOr(undefined, ["product_id"], defaultFilterValues),
    created_at: {
      from: pathOr(undefined, ["created_at", "from"], defaultFilterValues),
      to: pathOr(undefined, ["created_at", "to"], defaultFilterValues)
    }
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 1,
    position: ["bottomright", "topright"],
    showSizeChanger: true
  });

  useEffect(() => {
    getTableData();
    /* eslint-disable-next-line */
  }, [filter]);

  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.Products.getFeedbacks({
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize
    });

    if (!_res.data) {
      return message.error("Ошибка получения данных", 2);
    }

    setData(_res.data.rows);
    setPagination({ ...params, total: _res.data.count });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter({ ...filter, [f]: v || undefined }, setPagination({ ...pagination, current: 1 }));
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      {includes("outcome_reason", showFilters) && [
        <div className="col-span-12 lg:col-span-4">
          <ProductFilter onChange={onFilterChange} data={filters.product_id} />
        </div>
      ]}
      {includes("product_id", showFilters) && [
        <div className="col-span-12 lg:col-span-4">
          <ReasonFilter onChange={onFilterChange} data={filters.outcome_reason} />
        </div>
      ]}
      {includes("created_at", showFilters) && [
        <div className="col-span-12 lg:col-span-4">
          <DateRange onChange={onFilterChange} />
        </div>
      ]}

      <div className="col-span-12 py-5">
        <span className="text-lg font-medium block text-center text-black">Выборка из {pagination.pageSize} записей, отображенных ниже</span>
        <Chart data={data} categories={filters.outcome_reason} />
        <Table
          className="mt-5"
          showHeader={showHeader}
          data={data}
          filters={filters.outcome_reason}
          getTableData={getTableData}
          pagination={pagination}
          loading={loading}
        />
      </div>
    </div>
  );
}
