import { Button, Divider, Tooltip } from "antd";
import { WhatsappShareButton, WhatsappIcon, TelegramIcon, TwitterShareButton, FacebookShareButton, TelegramShareButton, FacebookIcon } from "react-share";
import { ShareAltOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import config from "@config";

export default function Preview({ data = {}, isApp = false }) {
  const { product } = data;
  const isPublished = product.status === "published";
  const productType = product.type === "investment" ? "startups" : product.type;
  const productLink = !isPublished
    ? `${config.frontend}/preview/${productType}/${product.id}`
    : `${config.frontend}/${productType}/${product.id}/${product.slug}`;
  return (
    <Fragment>
      <span className="block text-xs text-neutral-400 whitespace-nowrap leading-none">{`ID ${product.id}`}</span>
      <div className="w-full bg-neutral-200 mx-3" style={{ height: 1 }}></div>
      {!isApp && (
        <Button rel="noreferrer" target="_blank" href={productLink} className="m-0 p-0 border-none h-fit bg-transparent" type="link">
          {!isPublished ? "Предпросмотр" : "Открыть на сайте"}
        </Button>
      )}
      {isPublished && <Divider type="vertical" />}
      {isPublished && (
        <Tooltip
          placement={isApp ? "left" : "bottom"}
          title={() => (
            <div className="w-full max-w-xs">
              <span className="block text-sm font-medium text-center w-full">Поделиться:</span>
              <div className="flex justify-center items-center gap-2 mt-2">
                <WhatsappShareButton url={productLink}>
                  <WhatsappIcon size={25} round />
                </WhatsappShareButton>
                <TelegramShareButton url={productLink}>
                  <TelegramIcon size={25} round />
                </TelegramShareButton>
                <FacebookShareButton url={productLink}>
                  <FacebookIcon size={25} round />
                </FacebookShareButton>
                <TwitterShareButton url={productLink}>
                  <TelegramIcon size={25} round />
                </TwitterShareButton>
              </div>
            </div>
          )}
        >
          <Button size="large" icon={<ShareAltOutlined />} type="link" className="m-0 p-0 border-none h-fit bg-transparent" />
        </Tooltip>
      )}
    </Fragment>
  );
}
