import { Card, Form, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import Publish from "./publish";
import Fade from "react-reveal/Fade";
import classNames from "classnames";

export default function InvestmentDetails({ loading, setStage, form, visible = false, isApp = false }) {
  const { t } = useTranslation();
  const onBackClick = () => {
    setStage(1);
  };
  return (
    <Fade spy={visible} exit={false} duration={300} distance="5px" bottom>
      <Card
        hidden={!visible}
        className={classNames("shadow-lg shadow-gray-100 transition-all", {
          "app-card": isApp
        })}
      >
        <h2 className="w-full text-center my-1">{t("listings.extraInfoTitle")}</h2>
        <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-10">{t("listings.extraInfoFranchiseText")}</span>

        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-12 lg:col-span-6">
            <Form.Item
              label={t("listings.yearProfitTitle")}
              help={t("listings.yearProfitHelp")}
              name={["investment", "year_profit_rate_min"]}
              rules={[
                {
                  type: "integer",
                  required: true,
                  message: t("listings.yearProfitPrompt")
                }
              ]}
            >
              <InputNumber min={0} max={100000} keyboard={false} controls={false} className="w-full" addonAfter="%" placeholder="Напр. 50" />
            </Form.Item>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <Form.Item
              label={t("listings.yearMaxProfitTitle")}
              help={t("listings.yearMaxProfitHelp")}
              name={["investment", "year_profit_rate_max"]}
              rules={[
                {
                  type: "integer",
                  required: true,
                  message: t("listings.yearMaxProfitPrompt"),
                  validator(_, value) {
                    if (value <= form.getFieldValue(["investment", "year_profit_rate_min"])) {
                      return Promise.reject(new Error(t("listings.yearMaxProfitError")));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <InputNumber min={0} max={100000} keyboard={false} controls={false} className="w-full" addonAfter="%" placeholder="Напр. 60" />
            </Form.Item>
          </div>
          <div className="col-span-12 lg:col-span-12">
            <Form.Item
              label={t("listings.stageTitle")}
              help={t("listings.stageHelp")}
              name={["investment", "stage"]}
              rules={[
                {
                  required: true,
                  message: t("listings.stagePrompt")
                }
              ]}
            >
              <Select placeholder="Выберите на какой стадии ваш проект">
                <Select.Option value="idea">{t("listings.stageIdea")}</Select.Option>
                <Select.Option value="team">{t("listings.stageTeam")}</Select.Option>
                <Select.Option value="operating_w_profit">{t("listings.stageProfit")}</Select.Option>
                <Select.Option value="operating_wo_profit">{t("listings.stageNoProfit")}</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </Card>

      <Publish isApp={isApp} visible={visible} form={form} loading={loading} onBackClick={onBackClick} />
    </Fade>
  );
}
