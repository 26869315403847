import { Notifications } from "@modules/customer";
import { useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useState } from "react";
import * as API from "@api/customer/notifications";

export default function NotificationsPage() {
  const { t } = useTranslation();
  const initialData = useLoaderData();
  const [loading, setLoading] = useState(false);

  const markAllAsRead = async () => {
    setLoading(true);
    const res = await API.readAll();
    if (res.error) {
      console.error(res.message);
    }

    // reload page
    setLoading(false);
    window.location.reload();
  };

  return (
    <div className="my-5">
      <div className="flex justify-between items-center flex-wrap gap-2 mb-5">
        <h2>{t("nav.notifications")}</h2>
        <Button onClick={markAllAsRead} loading={loading} disabled={loading} className="!m-0 !p-0 h-fit min-h-0" type="link">
          {t("notifications.markAllRead")}
        </Button>
      </div>
      <Notifications initialData={initialData} />
    </div>
  );
}
