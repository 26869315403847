import axios from "axios";
import config from "@config";
const API = `${config.api}/auth`;

// Returns current user auth details
export const getAuth = () =>
  axios
    .get(`${API}/`, {
      withCredentials: true,
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0"
      }
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

// Sign In user
export const signin = (data, params) =>
  axios
    .post(`${API}/signin`, data, { params }, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

// Logout user
export const logout = data =>
  axios
    .post(`${API}/logout`, data, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

// Send Code
export const sendCode = data =>
  axios
    .post(`${API}/phone/send-code`, data, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

// Send Code
export const confirmPhone = data =>
  axios
    .post(`${API}/phone/confirm`, data, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

// Send Code via SMSC
export const SMSCsendCode = data =>
  axios
    .post(`${API}/phone/smsc/send-code`, data, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

// Confirm Code via SMSC
export const SMSCconfirmPhone = data =>
  axios
    .post(`${API}/phone/smsc/confirm`, data, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

// Confirm Code with Whatsapp
export const whatsappSendCode = data =>
  axios
    .post(`${API}/phone/whatsapp/send-code`, data, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));
export const whatsappConfirmCode = data =>
  axios
    .post(`${API}/phone/whatsapp/confirm`, data, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const checkEmailUnique = data =>
  axios
    .post(`${API}/email/unique`, data, { withCredentials: true })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));
