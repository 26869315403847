import { Tooltip } from "antd";
import { PhoneOutlined, DashOutlined, EyeOutlined, PicCenterOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function Activity({ data }) {
  const { t } = useTranslation();

  return (
    <div className="md:border md:border-solid border-neutral-200 h-full rounded-md p-0 md:p-4 md:my-1">
      <div className="w-full">
        <span className="flex justify-start items-center gap-2 text-sm leading-none text-neutral-400">
          {t("listings.activity")}{" "}
          <i className="inline-block align-middle font-medium text-[10px] bg-primary text-white px-[7px] py-[4px] leading-none rounded-full">NEW</i>
        </span>
        <div className="grid grid-cols-3 gap-2 mt-3">
          <div className="col-span-3 lg:col-span-1">
            <Tooltip placement="bottom" title={t("listings.analyticsViews")}>
              <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
                <span className="block text-center text-sm font-medium mb-1 w-full">
                  {data?.company?.clicks_metric <= 0 ? <DashOutlined /> : data?.company?.clicks_metric}
                </span>
                <EyeOutlined className="mx-auto block text-neutral-400" style={{ fontSize: 17 }} />
              </div>
            </Tooltip>
          </div>
          <div className="col-span-3 lg:col-span-1">
            <Tooltip placement="bottom" title={t("listings.analyticsShows")}>
              <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
                <span className="block text-center text-sm font-medium mb-1 w-full">
                  {data?.company?.shows_metric <= 0 ? <DashOutlined /> : data?.company?.shows_metric}
                </span>
                <PicCenterOutlined className="mx-auto block text-neutral-400" style={{ fontSize: 17 }} />
              </div>
            </Tooltip>
          </div>
          <div className="col-span-3 lg:col-span-1">
            <Tooltip placement="bottom" title={t("listings.analyticsRequests")}>
              <div className="w-full h-full text-center bg-neutral-100 rounded-md py-4 px-2">
                <span className="block text-center text-sm font-medium mb-1 w-full">
                  {data?.company?.contacts_metric <= 0 ? <DashOutlined /> : data?.company?.contacts_metric}
                </span>
                <PhoneOutlined className="mx-auto block text-neutral-400" style={{ fontSize: 17 }} />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
