import { Modal } from "antd";
import { useState } from "react";
import List from "./list";
import Add from "./add";

export default function ContactsModal({ visible = false, onClose, selected = [], onSelect = () => {} }) {
  const [page, setPage] = useState("list");

  const onDestroy = () => {
    setPage("list");
    onClose();
  };

  return (
    <Modal destroyOnClose={true} footer={null} closeIcon={() => null} open={visible} onCancel={() => onClose()} width={500} maskClosable>
      {page === "list" && <List onSelect={onSelect} selected={selected} onPageChange={setPage} onClose={onDestroy} />}
      {page === "add" && <Add onSelect={onSelect} onPageChange={setPage} onClose={onDestroy} />}
    </Modal>
  );
}
