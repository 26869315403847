import axios from "axios";
import config from "@config";

const API = `${config.api}/customer/notifications`;

export const getAll = params =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const read = data =>
  axios
    .post(`${API}/read`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));

export const readAll = data =>
  axios
    .post(`${API}/read/all`, data, {
      withCredentials: true
    })
    .then(res => res.data)
    .catch(err => ({
      error: true,
      message: err.message || err?.response?.data || "Ошибка сервера"
    }));
