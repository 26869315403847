import { useEffect, useState } from "react";
import { Image as AntImage, message, Button, Tooltip, Popconfirm, Divider } from "antd";
import { CloseOutlined, LoadingOutlined, PictureOutlined } from "@ant-design/icons";
import { remove } from "ramda";
import { useAuth } from "@contexts";
import { useDropzone } from "react-dropzone";
import { Imgproxy } from "@utils";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { useTranslation } from "react-i18next";
import * as API from "@api";

export default function Photos({ form }) {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState(form.getFieldValue("image_list") || []);
  const { getRootProps, getInputProps } = useDropzone({
    disabled: loading || photos.length >= 10,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpeg", ".jpg"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/webp": [".webp"]
    },
    onDrop: async files => {
      // Ensure array
      if (!Array.isArray(files)) {
        files = [files];
      }

      if (files.length + photos.length > 10) {
        return message.error(t("listings.photosMaxQtyError"));
      }

      /* eslint-disable-next-line */
      files.map(file => {
        if (file.size > 5 * 1000 * 1000) {
          return message.error(t("listings.photosMaxSizeError"));
        }
      });

      try {
        setLoading(true);
        const _res = await API.Static.uploadFiles({
          files,
          id: currentUser.id
        });

        if (!_res.data || _res.error) {
          throw new Error();
        }

        setLoading(false);
        setPhotos([...photos, ..._res.data.map(file => file.path)]);
        return message.success(t("listings.photosUploaded"), 2);
      } catch (error) {
        setLoading(false);
        return message.error(t("generic.serverError"), 2);
      }
    }
  });

  useEffect(() => {
    form.setFieldsValue({ image_list: photos });
    /* eslint-disable-next-line */
  }, [photos]);

  const onSortEnd = (oldIndex, newIndex) => {
    setPhotos(array => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const onImageDelete = idx => {
    setPhotos(remove(idx, 1, photos));
  };

  return (
    <div>
      <Divider>{t("listings.photosTitle")}</Divider>
      <span className="block w-full max-w-md m-auto text-center text-neutral-500 text-sm mb-2">{t("listings.photosTooltip")}</span>
      <SortableList onSortEnd={onSortEnd} className="flex flex-wrap select-none justify-start gap-2" draggedItemClassName="dragging-item">
        {photos.map((item, key) => (
          <SortableItem key={item}>
            <div className="draggable-item">
              <AntImage width="100%" height="100%" src={Imgproxy.getURL(item)} className="object-cover object-center" />
              <Popconfirm title={t("listings.deletePhoto")} okText={t("generic.yes")} cancelText={t("generic.cancel")} onConfirm={() => onImageDelete(key)}>
                <Tooltip placement="bottom" title="Удалить фото">
                  <Button size="small" className="absolute top-2 right-2" danger type="primary" shape="circle" icon={<CloseOutlined />} />
                </Tooltip>
              </Popconfirm>
            </div>
          </SortableItem>
        ))}
      </SortableList>

      <div
        // hidden={photos.length >= 10}
        className="ant-upload ant-upload-drag cursor-pointer hover:border-primary transition-long hover:transition-long bg-neutral-50 border-dashed border border-neutral-300 rounded-md my-5 py-8"
        {...getRootProps()}
      >
        <input {...getInputProps()} accept=".png, .jpg, .jpeg" />
        <p className="mx-auto block w-fit text-5xl mb-3 text-primary">{loading ? <LoadingOutlined /> : <PictureOutlined />}</p>
        <p className="text-sm text-black w-full block text-center">{t("listings.dragToLoad")}</p>
        <p className="text-xs text-neutral-500 w-full block text-center mt-2">{t("listings.photoMaxSize")}</p>
      </div>
    </div>
  );
}
