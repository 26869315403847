import { Card, Button } from "antd";
import { YoutubeFilled, GlobalOutlined, InstagramFilled, FacebookFilled } from "@ant-design/icons";

export default function Options({ data, className }) {
  console.log("data", data);

  return (
    <Card bordered={false} title="Ссылки на соц.сети" className={className}>
      <div className="flex flex-col gap-2 justify-start items-start">
        {data?.product?.meta?.youtube_channel_url && (
          <Button icon={<YoutubeFilled />} target="_blank" href={data?.product?.meta?.youtube_channel_url} type="link" className="!px-0">
            {data?.product?.meta?.youtube_channel_url}
          </Button>
        )}
        {data?.product?.meta?.instagram_url && (
          <Button icon={<InstagramFilled />} target="_blank" href={data?.product?.meta?.instagram_url} type="link" className="!px-0">
            {data?.product?.meta?.instagram_url}
          </Button>
        )}
        {data?.product?.meta?.facebook_url && (
          <Button icon={<FacebookFilled />} target="_blank" href={data?.product?.meta?.facebook_url} type="link" className="!px-0">
            {data?.product?.meta?.facebook_url}
          </Button>
        )}
        {data?.product?.meta?.tiktok_url && (
          <Button icon={<GlobalOutlined />} target="_blank" href={data?.product?.meta?.tiktok_url} type="link" className="!px-0">
            {data?.product?.meta?.tiktok_url}
          </Button>
        )}
      </div>
    </Card>
  );
}
