import { Card, Form, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export default function ListingPublish({ visible, form, loading, onBackClick, isApp = false }) {
  const { t } = useTranslation();

  return (
    <Card
      hidden={!visible}
      className={classNames("shadow-lg shadow-gray-100 transition-all my-5 p-0", {
        "app-card": isApp
      })}
    >
      <div className="flex justify-between items-center">
        <Button type="link" className="p-0 m-0" onClick={onBackClick}>
          {t("generic.back")}
        </Button>
        <Form.Item className="m-0">
          <Button loading={loading} className="m-0" htmlType="submit" type="primary">
            {t("generic.publish")} <RightOutlined />
          </Button>
        </Form.Item>
      </div>
    </Card>
  );
}
